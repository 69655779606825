.creat-account{
    display: none;
}

.create-account-btn{
    background-color: #cb861e;
    color: rgb(235, 235, 235);
    font-weight: 400;
    font-size: 16px;
    padding: 10px;
    border-radius: 2px;
    border: none;
    width: 45%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.create-account-btn:hover{
    background-color: rgb(207, 142, 45);
    color: white;
    cursor: pointer;
}

@media screen and (max-width: 767px) {

    /* .creat-account{
        display: block;
    } */
    
    .create-account-btn{
        width: 60%;
        padding: 12px;
        margin-top: 2rem;
    }
}


@media (min-width: 767px) and (max-width: 830px) {  /* smaller tablet  */

    /* .creat-account{
        display: block;
    } */
    
    .create-account-btn{
        width: 60%;
        padding: 18px;
        margin-top: 3rem;
    }
}

/* tablet  */
@media (min-width: 830px) and (max-width: 1025px) {  /* tablet  */
    /* .creat-account{
        display: block;
    } */
    
        .create-account-btn{
            width: 70%;
            padding: 20px;
            margin-top: 3rem;
        }
    }



    @media(max-height: 500px) {
        .create-account-btn{
            padding: 8px;
            margin-top: 6rem;
        }
    }