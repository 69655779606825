.three-boxes-container {
  display: flex; /* Align boxes in a row */
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 12rem; 
  background-color: white;
  border-radius: 4px;
}

.boxx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1;
  background-color: #f0f0f0;
  transition: transform 0.1s ease;
  cursor: pointer;
  border-radius: 4px;
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
}

.boxx:hover {
  transform: scale(1.01);
  box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.2);
}

.boxx:first-child:hover, 
.boxx:last-child:hover {
  box-shadow: -4px 0px 12px rgba(0, 0, 0, 0.2), 4px 0px 12px rgba(0, 0, 0, 0.2); /* Shadow only on left and right */

}

.boxxinner{
  width: 100%;
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;

}

.box-icon {
  font-size: 1.5rem;
  color: #c99211;
}

h3 {
  font-size: 17px;
  color: #333;
  margin-bottom: 0;
}

.boxxinner p {
  font-size: 12px;
  color: hsl(0, 0%, 47%);
  margin-bottom: 0;
  margin-top: 5px;
}

/* Responsive Design */
@media (max-width: 768px) {
  /* .three-boxes-container {
    flex-direction: column;
    display: none;
  } */

  .three-boxes-container {
    height: 7rem; 
  }

  .box-icon {
    font-size: 1.2rem;
  }
  
  h3 {
    font-size: 12px;
  }
  
  .boxxinner p {
    font-size: 10px;
  }
}