.articles-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 5rem;
}



.articles-home{
width: 100%;
height: 25vh;

}

.articles-home .overlay h1{
font-size: 2rem;
color: #ebeeef;
}



.articles-home .overlay{
width: 100%;
height: 25vh;
background-color: rgba(0, 0, 0, 0.624);
position: absolute;
display: flex;
justify-content: center;
align-items: center;

}

.articles-home img{
width: 100%;
height: 100%;
object-fit: cover;
}

h1 {
  margin-bottom: 20px;
}

.search-bar {
  margin-bottom: 20px;
  background-color: rgb(235, 241, 255);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar {
  flex: 0 0 25%;
  padding: 10px;

}

.sidebar h2{
  position: relative;
  left: 10px;
}

.main-content {
  flex: 1;
  padding: 15px;
}

.tab-container {
  margin-top: 20px;
  width: 80%;
  padding: 20px;
}

.tab {
  cursor: pointer;
  padding: 5px;
  width: 100%;
  margin-bottom: 10px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.tab .active {
  background-color: #0d4d5e;
  color: #ffffff;
}

.tab .active:hover {
    background-color: #0d4d5e;
}

.tab a {
    text-decoration: none;
    color: #000000;
    font-size: 16px;
}   

.tab:hover {
  background-color: #e6e6e6;
}
.article-content{
  display: flex;

}

.articles-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 30px;
}

.article-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align: left;
  text-decoration: none;
  color: inherit;
  transition: transform 0.3s ease;
  height: 400px;
  overflow: hidden;
}

.article-card:hover {
  transform: translateY(-5px);
}

.article-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
}

.article-card h2 {
  margin: 10px 0;
  font-size: 1.5em;
}

.article-card p {
  color: #666;
}

.search-bar input {
  width: 60%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}


@media screen and (max-width: 767px){
  .sidebar {
      display: none;
  
    }
}