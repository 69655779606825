/* Navbar.css */
/* Desktop Navbar */
.desktop-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 3rem;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  transform: translateY(0);
  opacity: 1;
  z-index: 1000;
}

.tracker-link{
text-decoration: none;
}

.desktop-navbar.hidden {
  transform: translateY(0);
  opacity: 1;
}

.desktop-navbar.visible {
  position: fixed;
  top: 5px;
  width: 100%;
  opacity: 1;
}



.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
  height: 2rem;
  width: 100%;
  margin: 0 auto;
  /* box-shadow: 0 0 5px rgba(8, 12, 95, 0.213); */
  /* background-image: repeating-linear-gradient(135deg, rgba(189,189,189,0.1) 0px, rgba(189,189,189,0.1) 2px,transparent 2px, transparent 4px),linear-gradient(90deg, rgb(255,255,255),rgb(255, 255, 255)); */
  background-color: white;
  cursor: pointer;
  border-right: #27394f 1px solid;
  z-index: 1000;

}

.navbar-container .logo-container{
  margin-left: 5.5%;

}

.navbar-container .logo-container img{
  width: 80%;
  height: 80%;
}

.navbar-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.navbar-links a {
  margin-right: 20px;
  color: #525e66;
  text-decoration: none;
  font-size: 16px;
}


.navbar-links a:hover {
      color: #dc8410;
  }

/* Mobile and Tablet Navbar */
.mobile-navbar {
  position: fixed;
  top: 20px;
  height: 4rem;
  width: 100%;
  padding: 10px;
  z-index: 1000;

}







/* Add this to your Dash.css file */
.hamburger-menu {
  display: block;
  width: 30px;
  height: 25px;
  z-index: 2000;
  cursor: pointer;
  position: relative;
  top: 12px;
  right: 30px;
  cursor: pointer;
  float: right;
}

.hamburger-menu div {
  background-color: white;
  height: 4px;
  width: 100%;
  position: absolute;
  transition: all 0.3s ease-in-out;
}

/* Top bar */
.hamburger-menu div:nth-child(1) {
  top: 0;
}

/* Middle bar */
.hamburger-menu div:nth-child(2) {
  top: 10px;
}

/* Bottom bar */
.hamburger-menu div:nth-child(3) {
  top: 20px;
}

/* Rotate into cross when sidebar is visible */
.hamburger-menu.open div:nth-child(1) {
  transform: rotate(45deg);
  background-color: #000;
  top: 10px;
}

.hamburger-menu.open div:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.open div:nth-child(3) {
  transform: rotate(-45deg);
  background-color: #000;
  top: 10px;
}


.nav-overlay{
  width: 100%;
  height: 100vh;

}


.overlay-menu {
  position: fixed;
  top: 0;
  right: 0;
  transition: transform 0.3s ease-out;
  padding: 15px;
  box-shadow: 0 0 10px rgba(8, 13, 95, 0.3);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 95%;
  height: 100%;
  background-color: #ffffff;
  float: right; 
  animation: slide 0.6s forwards;
  backdrop-filter: blur(25px);

}

.nav-addri{
  position: relative;
  bottom: 2rem;
}


.NavLinks{
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 0.5rem;
  width: 85%;
  margin-left: 15px;

}

.link-icon{
  display: flex;
  padding: 5px;
  gap: 15px;
  align-items: center;

}

.link-icon a{
  font-size: 12px;
  color: #323232;
}


#authlinkone{
  background-color: #455b74;
  padding: 7px;

}

#authlinktwo{
  background-color: #27394f;
  padding: 7px;
}


#authlinkone a,
  #authlinktwo a{
      color: white;
      padding-left: 12px;
      border-radius: 5px;
      font-size: 14px;
  }

.menu-footer{
  width: 97%;
  height: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

}


.footer-items{
  display: flex;
  align-items: center;
 
  
}

.footer-item img{
  object-fit: contain;
}


.footer-item {
  display: flex;

}

.footer-item-info{
  display: flex;
  flex-direction: column;
  justify-content: center;

}


.si{
  line-height: 5px;
  width: auto;
  padding-left: -6px;

}

.footer-item p{
  color: #323232;
  font-size: 12px;
  font-weight: 200;
  margin-left: 10px;
}

.overlay-menu .side-nav-lnks {
      color: #000000;
      text-decoration: none;
      font-size: 16px;
      font-weight: 100;
  }



@keyframes slide {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}


/* Media Queries */
@media only screen and (max-width: 1075px) {
  .desktop-navbar {
      display: none;
  }
  .mobile-navbar {
      display: block;
      position: fixed;
      top: 12px;
  }

  .navbar-container{
    width: 100%;
  }

}

@media only screen and (min-width: 1075px) {
  .desktop-navbar {
      display: block;
  }
  .mobile-navbar {
      display: none;
  }

  

  
}


@media only screen and (max-height: 500px) {
  .desktop-navbar {
      display: none;
  }
  .mobile-navbar {
      display: block;
      position: fixed;
      top: 5px;
  }

.NavLinks{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
  width: 90%;
}
.navbar-container{
  width: 100%;
}

}


@media(max-width: 376px) {
.NavLinks{
  display: flex;
  flex-direction: column;
  gap: 1.1rem;
  padding: 0.5rem;
  width: 90%;

}

.link-icon{
  display: flex;
  padding: 1px;
  gap: 5px;
  align-items: center;
}
}