.admin-profile-avatar{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #e5e5e5;
  margin-top: 10px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

}

.admin-profile-avatar h2{
  color: #16425b;
  font-weight: bold;
}


.dash-dashboard {
  display: flex;
  height: 100vh;
  font-family: "Poppins",sans-serif;
}

.dash-sidebar {
  flex: 0.3;
  width: 17.9%;
  background-color: #151b32;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
  z-index: 500;
}

.dash-sidebar.hidden {
  transform: translateX(-100%);
}

.dash-tabs {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 2rem;
  gap: 10px;

}

.dash-tab {
  padding: 5px 7px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: background-color 0.3s, border-left-color 0.3s;
  color: #ffffffc8;
  font-size: 14px;
  font-family: "Poppins",sans-serif;
  display: flex;
  align-items: center;
  gap: 10px;

}

.dash-tab:hover {
  color: #f4f4f4;
  background-color: #0d0f1f27;
}

.dash-tab.active {
  border-left: 3px solid rgb(218, 131, 1);
  color: #eaeaea;
}

.dash-content {
  flex: 0.7;
  transition: margin-left 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 18%;
  width: 80%;
  height: auto;
  background-color: white;
  padding: 10px;
  border: none;
  margin-top: 20px;
}

.dash-content.dash-sidebar-hidden {
  margin-left: 0;
  width: 100%;
}

.dash-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.5em;
}

/*start hamburger menu animation */


.dash-hamburger div {
  background-color: rgb(188, 188, 188);
  height: 4px;
  width: 80%;
  position: absolute;
  transition: all 0.3s ease-in-out;
}

/* Top bar */
.dash-hamburger div:nth-child(1) {
  top: 0;
}

/* Middle bar */
.dash-hamburger div:nth-child(2) {
  top: 10px;
}

/* Bottom bar */
.dash-hamburger div:nth-child(3) {
  top: 20px;
}

/* Rotate into cross when sidebar is visible */
.dash-hamburger.open div:nth-child(1) {
  transform: rotate(45deg);
  top: 10px;
}

.dash-hamburger.open div:nth-child(2) {
  opacity: 0;
}

.dash-hamburger.open div:nth-child(3) {
  transform: rotate(-45deg);
  top: 10px;
}
.dash-hamburger {
  display: none;
  font-size: 2em;
  cursor: pointer;
  position: absolute;
  top: 35px;
  right: 25px;
}

.tracking-home-section {
  position: relative;
  height: 200px;
  overflow: hidden;
  border-radius: 8px;
}

.tracking-home-section video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* end of hamburger menu animation */

@media (max-width: 768px) {


  .dash-sidebar {
    width: 100%;
    height: 100%;
    background-color: #131828;
    transition: transform 0.3s ease-in-out;
    z-index: 2000;

  }

  .dash-hamburger {
    display: block;
    padding: 20px;
    z-index: 2000;
    color: white;

  }


  .dash-sidebar.hidden {
    transform: translateX(-100%);
  }
  

  .dash-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

  }


  .dash-content.dash-sidebar-hidden {
    margin-left: 0;
    width: 100%;
  }

  .dash-tabs {
   padding-top: 4rem;
  
  }
}


