
.about-section .home-section {
    background-image: url(../assets/images/bulb-img.jpg);
    background-size: cover;
    height: 50vh;
    display: flex;
    justify-content: center;


}

.about-left-column h1, p {
    color: #f3f3f3;
}



.about-section .about-left-column {
    width: 50%;
    z-index: 1;
}


.highlights-section{
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
}


.hightlights-text{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
    flex-direction: column;
    margin-bottom: 2rem;
    color: #375168;
}


.highlightboxes{
    width: 100%;
    height: 40vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly

}


.highlightbox{
    border-radius: 10px;
    width: 25%;
    height: 87%;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    color: #6e8293;
    box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.3);

}

.highlightbox p{
   font-size: 16px;
   color: #6e8293;
   font-weight: 300;

}


.our-mission-section{
    width: 70%;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;

}


.our-mission-section p{
    color: #3d5365;
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    padding: 0 2rem;
    margin-bottom: 2rem;
}

.mini-heading{
    color: #1b2634;
    font-size: 20px;
}

.our-team-section{
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.3);


}

.team-heading{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh;
    padding-bottom: 2rem;
    flex-direction: column;
    margin-bottom: 2rem;
}

.team-members{
    width: 100%;
    height: 60vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-bottom: 5rem;
    
}


.team-member{
    border-radius: 10px;
    width: 20%;
    height: 70%;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
    background-color: #fff;
    color: #6e8293;
    box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.3);

}


.contact-section{
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 4rem;

}


.contact{
    display: flex;
    gap: 5rem;
    width: 90%;
    align-items: center;

}

.contact-info-left{
    flex: 1;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;


}

.contactinfo{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 6rem;
    line-height: 20px;
    padding: 5px;


}

.contact-info-left p{
    color: black;
}


.contact-info-right p{
    color: black;
}


.contact-info-right{
    flex: 1;   

}


.about-overlay{
    width: 100%;
    height: 50vh;
    position: absolute;
    background-color: #20233577;
}

.left-column{
    z-index: 1;
    width: 70%;
}



@media screen and (max-width: 900px) {

    .about-section .about-left-column {
    
        width: 80%;

    }

    .highlights-section{
        align-items: center;
        height: 100vh;
    }

.highlightboxes{
    width: 85%;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    
}


.highlightbox{
    border-radius: 10px;
    width: 100%;
    height: 40%;
    

}

.highlightbox h1{
    font-size: 24px;
    
 
 }

.highlightbox p{
   font-size: 10px;
   color: #6e8293;
   font-weight: 300;

}


.our-mission-section{
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: #1b2634;

}


.our-mission-section p{
    color: #ffffff;
    font-size: 14px;
    font-weight: 300;
    text-align:start;
    padding: 0 2rem;
    margin-bottom: 2rem;
}


.our-mission-section h1{
    color: white
}



.our-team-section{
    width: 100%;
    height: auto;

}

.team-members{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    
}


.team-member{
    border-radius: 10px;
    width: 80%;
    height: 80%;

}


.contact-section{
    flex-direction: column;
    height: 110vh;

}


.contact{
    display: flex;
    gap: 1rem;
    width: 90%;
    flex-direction: column;
    background-color: #1b2634;
    align-items: flex-start;
    

}


.contact-info-left{
    width: 70%;

}

.contact-info-left p{
    color: rgb(255, 255, 255);
    font-size: medium;
    
}


.contact-info-right{
    width: 90%;
    padding-left: 2rem;

}

.contact-info-right p{
    color: rgb(255, 255, 255);
    font-size: medium;
    width: 90%;
}


.about-section .home-section {
    height: 60vh;

}

}


@media(max-height: 500px) {

    .contact-submain{
        height: 100vh;
    }

    .contact-container{
        width: 85%;
    }   

    .contact-query{
        width: 80%;
    
    }
    .our-mission-section{
        height: 100vh;

    }

    .our-mission-section p{
        font-size: 14px;

    }

    .contact-section{
        height: 150vh;
    
    }

    
    .about-section .home-section {
        height: 100vh;
    }

    .about-overlay{

        height: 100vh;

    }

    .highlights-section{
        height: 100vh;
    }


    .highlightbox{
        width: 25%;
        height: 100%;

    
    }
}


@media(max-width: 376px) {

    .about-section .home-section {
        height: 70vh;
    
    }

    .about-overlay{
        height: 70vh;

    }

    .our-mission-section{
        height: 100vh;

    
    }

    .contact-section{
        height: 110vh;
    
    }


}
