.scroll-indicator-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #ccc;
    z-index: 9999;
  }
  
  .scroll-indicator-line {
    height: 100%;
    background-color: orange;
  }
  