.admin-container {
    max-width: 800px;
    padding: 20px;
  }

  .admin-container h2 {
    text-align: left;
  }
  
  .admin-form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;

  }
  .admin-form div{
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  
  .admin-form input {
    flex: 1 1 calc(50% - 20px);
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .admin-form button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
  }
  
  .admin-form button:hover {
    background-color: #0056b3;
  }
  
  .schedule-list {
    list-style: none;
    padding: 0;
  }
  
  .schedule-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .schedule-info {
    flex: 1;
  }
  
  .schedule-actions {
    display: flex;
    gap: 10px;
  }
  
  .schedule-actions button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .schedule-actions button:first-of-type {
    background-color: #28a745;
    color: white;
  }
  
  .schedule-actions button:last-of-type {
    background-color: #dc3545;
    color: white;
  }
  
  .schedule-actions button:hover:first-of-type {
    background-color: #218838;
  }
  
  .schedule-actions button:hover:last-of-type {
    background-color: #c82333;
  }
  
  @media (max-width: 600px) {
    .admin-form input {
      flex: 1 1 100%;
    }
  
    .schedule-item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .schedule-actions {
      width: 100%;
      justify-content: space-between;
    }
  
    .schedule-actions button {
      flex: 1 1 calc(50% - 10px);
      margin-top: 10px;
    }
  }
  