.legal-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;

}

.home{
    width: 100%;
    height: 40vh;

}


.overlay{
    width: 100%;
    height: 40vh;
    background-color: rgba(0, 0, 0, 0.624);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

}

.overlay h1{
    font-size: 2rem;
    color: #ebeeef;
}

.home img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.tabs-section {
    display: flex;
    flex-direction: column;
}

.tabs-section .tab {
    cursor: pointer;
    padding: 15px;
    width: 10rem;
    margin-bottom: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    background-color: rgb(255, 255, 255);
    
}

.tabs-section .tab:hover {
    background-color: white;
    color: #575451;
    transform: scale(1.05);
}

.tabs-section .tab.active {
    background-color: #5b4a37;
    border-bottom: 2px solid #ff9225;
    color: #fff;
}

.legal-section-details{
    display: flex;
    gap: 20px;
    padding: 20px;
    width: 85%;
}

.info-section {
    flex: 1;
    height: auto;

}

.info-content {
    padding: 40px;
    
}

.info-content h3 {
    margin-bottom: 10px;
    color: #93a8ae ;
}


.info-content p {
    color: #625f5c;
    animation: appear linear 0.9s;
    animation-timeline: view();
    animation-range: entry 0% cover 40%;
  
  }

  @keyframes appear {
    from {
      opacity: 0;
      scale: 0.5;
    }
    to {
      opacity: 1;
      scale: 1;
    }
  }


.shipping-guidelines-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
}

.box-grid {
    display: flex;
    flex-wrap: wrap;

}

.box {
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.box h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 20px;
}

.box ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.box ul li {
    margin-bottom: 5px;
}




@media screen and (max-width: 900px) {
    .legal-section-details{
        display: block;
        width: 90%;
    

    }


    .tabs-section .tab {
        width: 85%;

        
    }


    .info-section {
        flex: 1;
        height: auto;
  
    
    }
    
    .info-content {
        padding: 1px;
        
    }

    }