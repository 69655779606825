/* Auth.css */

/* Base styles */
.auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f7f7f7;
    padding: 20px;
}

.auth-form {
    background: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
}

.auth-form h2 {
    margin-bottom: 20px;
    color: #333;
}

.auth-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.auth-form button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.auth-form button:hover {
    background-color: #0056b3;
}

.auth-form p {
    margin-top: 15px;
    color: #555;
}

.auth-form p a {
    color: #007bff;
    text-decoration: none;
}

.auth-form p a:hover {
    text-decoration: underline;
}

/* Responsive styles */
@media (max-width: 768px) {
    .auth-form {
        padding: 20px;
        width: 90%;
        max-width: none;
    }

    .auth-form h2 {
        font-size: 1.5em;
    }

    .auth-form input,
    .auth-form button {
        padding: 12px;
        font-size: 1em;
    }

    .auth-form p {
        font-size: 0.9em;
    }
}

@media (max-width: 480px) {
    .auth-form {
        padding: 15px;
    }

    .auth-form h2 {
        font-size: 1.2em;
    }

    .auth-form input,
    .auth-form button {
        padding: 10px;
        font-size: 0.9em;
    }

    .auth-form p {
        font-size: 0.8em;
    }
}
