.profile-container {
  position: fixed;
  top: 10px;
  width: auto;
  padding-left: 15px;
  height: 2.8rem;
  right: 10px;
  /* box-shadow: 0 0 10px rgba(8, 12, 95, 0.213); */
  border-radius: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* background-image: repeating-linear-gradient(135deg, rgba(189,189,189,0.1) 0px, rgba(189,189,189,0.1) 2px,transparent 2px, transparent 4px),linear-gradient(90deg, rgb(255,255,255),rgb(255,255,255)); */
  /* background-color: white; */
  z-index: 1000;

}

.profile-icon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;

}

.flag-container {
  display: flex;
  align-items: center;
  gap: 7px;

}

.tracking-link {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tracking-link a {
  text-decoration: none;
  border-bottom: 1px solid rgb(210, 210, 210);
}

.flag {
  width: 1rem;
  height: 1rem;

}

.logout-section p {
  color: #0a499d;
}


.profile-icon:hover {
  background-color: #f4f4f4;
}

.profile-icon img {
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  border-radius: 50%;
  filter: contrast(0.8) brightness(2.2);
}

.profile-text {
  margin-right: 10px;
  width: auto;
}

.profile-text-status {
  font-size: 13px;
  color: #ffffff;
  transition: transform 0.3s ease-out;
}

.dropdown-menu {
  position: absolute;
  top: 60px;
  right: 10px;
  background-color: #f5f5f5;
  border: none;
  border-radius: 10px;
  /* padding: 20px; */
  width: 20vw;
  height: auto;
  margin-top: 5px;
  padding-bottom: 10px;
  padding: 10px;
  border-bottom: 4px solid #092952;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  animation: fadeIn 0.5s ease forwards;
}

  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

.dropdown-menu::before {
  content: '';
  position: absolute;
  top: -10px;
  right: 10px;
  transform: translateX(-50%);
  border-style: solid;
  border-color: transparent transparent #fff transparent;
}


.dropdown-menu button {
  width: 100%;
  padding: 7px;
  border: none;
  background-color: #f6c32900;
  border: 1.5px solid #f6c329;
  color: #000000;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.dropdown-menu button:hover {
  background-color: #f4b60d;
  color: white;
}


.profile-copy-address {
  background-color: rgb(0, 47, 64);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11px;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  gap: 10px;

}

.profile-copy-address p {
  font-size: 11px;
}

.profile-copy-address svg:hover {
  transform: scale(1);
  color: orange;
  cursor: pointer;

}

.user-info {
  margin: 20px;

}

.user-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 5px;
  filter: contrast(0.3) brightness(1.2);

}

.logout-section {
  background-color: rgb(203, 223, 242);
  padding: 20px;

}

.logout-section button {
  background-color: #f44336;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
}

.logout-section button:hover {
  background-color: #d32f2f;
}

.logout-section p {
  cursor: pointer;
  text-decoration: none;
}


.desktop-profile-link {
  display: flex;
  gap: 20px;
  align-items: center;

}

.desktop-profile-link a {
  color: rgb(8, 20, 5);
}


.desktop-profile-link-upper {
  display: flex;
  align-items: center;
  gap: 10px;

}

.desktop-profile-link-upper p,
.desktop-profile-link-upper a {
  color: black;
  cursor: pointer;

}

@media (max-width: 1025px) {
  .profile-container {
    width: 15%;
    display: none;

  }

}


@media only screen and (max-height: 500px) {
  .profile-container {
    display: none;
  }

}