.create-article-container {
    width: 95%;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    font-family: "Poppins",sans-serif;
  }
  
  .create-article-container h2 {
    margin-bottom: 30px;
    color: #333;
    text-align: left;
  }
  
  .create-article-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #555;
  }
  
  .form-group input,
  .form-group textarea {
    width: calc(100% - 20px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .form-group textarea {
    resize: vertical;
    height: 150px;
  }
  
  .article-button[type="submit"] {
    width: 100%;
    padding: 10px 0;
    background-color: #FA9101;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .article-button[type="submit"]:hover {
    background-color: #0056b3;
  }
  