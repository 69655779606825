.main-input{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;


}
.crossfireui{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    justify-content: center;
    align-items: center;
    color:rgb(51, 113, 115);
    
}

.main-input form{
    display: flex;
    width: 50%;
}

.inner-main-input{
    width: 100%;
    padding: 15px;
    background-color: #02111b13;
    border: none;

}


.input-logo{
    position: absolute;
    top: 10px;
    width: 10%;
    height: 10%;
}


.input-logo img{
    width: 100%;
}


.inner-main-button{
    width: 20%;
    background-color: #16425b;
    color: #f9f9f9;
}


.inner-main-button:hover{
    background-color: #16425b;  
    transition: 0.5s;
    transform: scale(1.05);
}



@media (max-width: 767px){
    .main-input form{
        width: 85%;
    }

    .input-logo{
        position: absolute;
        top: 10px;
        width: 20%;
        height: 20%;
}
}


@media (min-width: 767px) and (max-width: 1025px) { 
    .main-input form{
        width: 70%;
    }

    .inner-main-input{
        padding: 25px;
    
    }

    
}