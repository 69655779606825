/* DisplayTrackingInfo.module.css */

.container {
    margin: 2rem auto;
    display: flex;

    padding: 1rem;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.main-container {
    width: 100%;
    background-color: #1b2634;
    gap: 2rem;
    padding: 30px;
    margin-top: 2.5rem;
}


.main-container h1 {
    font-size: 28px;
    border-bottom: 2px solid #ffffff;
    padding: 10px;
}

.main-container p {
    color: #f3f3f3;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 1.5rem;
}

.header {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: #ffffff;
}

.info {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 0.5rem;
    color: #b5b5b5;
}

.info span {
    font-weight: bold;
}
