.second-intro-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
    background-color: #02111B;
    border-top: 5px solid #c4c9bd;
    border-bottom: 5px solid #c4c9bd;
    padding-top: 2rem;

}


.main-split{
    width: 90%;
    display: flex; 
  
}

.tab-section{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 55%;
    padding: 30px;


}

.split-intro-two{
    width: 40%;
    padding: 10px;
    border-radius: 20px;

}

.second-intro-container p {
    font-size: 16px;
    margin-bottom: 1rem;
    color: #e9f0f1;
}

.second-intro-container h1{
    color: #e9f0f1;
    font-size: 36px;
    line-height: 43px;
}

.tab-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

}

.tabs {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    
}

.tab {
    cursor: pointer;
    padding: 5px 10px;
    margin: 0 5px;
    width: 5rem;
    background-color: #e9f0f1;
    transition: background-color 0.3s ease;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.tab.active {
    background-color: #23262b;
    color: #fff;
}

.content-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 20px;

}

.content {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

}

.tab-content {
    display: none;
}

.tab-content.active {
    display: flex;
}

.text {
    flex: 1;
    padding: 20px;
    color: #000;
}


.tab-image{
    width: 25rem;

}

.tab-image img {
    max-width: 300px;
    max-height: 200px;
    border-radius: 5px;

}



@media screen and (max-width: 767px) {
    .second-intro-container {
        height: 90vh;
    
    }

    .main-split{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        
    }

    .split-intro-two{
        width: 98%;
    }


    .tab-image{
        width: 90%;
    }
    .second-intro-container h1 {
        font-size: 30px;
        font-weight: 600;

    }

    .second-intro-container p {
        font-size: 16px;
  
        color: #e9f0f1;
    }
    
    .tab-section{
        width: 80%;

    }
    
    

}

/* smaller tablet  */
@media (min-width: 767px) and (max-width: 830px) {  /* smaller tablet  */

    .main-split{
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .split-intro-two{
        width: 90%;
    }

    .second-intro-container {
        height: 90vh;
   
    }

    
}



/* tablet  */
@media (min-width: 830px) and (max-width: 1025px) {  /* tablet  */
    
        .main-split{
            flex-direction: column;
            justify-content: center;
            align-items: center;
    
        }
    
        .split-intro-two{
            width: 90%;
        }

        .split-intro-two p{
            font-size: 20px;
        }
    
        .second-intro-container {
            height: 80vh;
    
        }
    
        
    }



    @media(max-height: 500px) {
        .second-intro-container {
            height: 130vh;

        }

        .split-intro-two{
            width: 90%;
        }


        .split-intro-two h1{
            font-size: 28px;
        }
    

        .split-intro-two p{
            font-size: 14px;
        }
    
 
        .main-split{
            width: 100%;
            display: flex; 
            height: 100vh;
          
        }
        
        .tab-section{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 55%;
            padding: 30px;
        
        
        }
        
        .split-intro-two{
            width: 90%;
            padding: 10px;
            border-radius: 20px;
        
        }
        
        .second-intro-container p {
            font-size: 14px;
            margin-bottom: 1rem;
            color: #e9f0f1;
        }
        
        .second-intro-container h1{
            color: #e9f0f1;
            font-size: 24px;
        }
        
      
    }