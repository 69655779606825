.whats-button {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 50%;
    background-color: #ffffff00;
    cursor: pointer;
    font-size: 16px;
    position: fixed;
    bottom: 30px;
    right: 16px;
    width: 45px;
    height: 45px;
    transition: transform 0.3s ease; /* Add transition for smooth transformation */
    z-index: 1000;
}

.whats-icon{
    width: 50px;
    height: 50px;
    margin-right: 10px;
    background-color: rgba(255, 255, 255, 0);
}



.whats-icon:hover{
    transform: scale(1.1);
}
