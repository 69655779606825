.change-password-container {
    width: 80%;
    padding: 10px;
  }
  
  .change-password-container h2 {
    color: #333;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .change-password-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }

  /* .form-group input::placeholder{
    font-size: 10px;
  } */
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #000000;
  }
  
  .form-control {
    width: 100%;
    border-radius: 4px;
  }
  
  .submit-button {
    background-color: #ff9900;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .error-message {
    color: #dc3545;
    margin-bottom: 10px;
  }
  
  .success-message {
    color: #28a745;
    margin-bottom: 10px;
  }
  
  .confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .confirmation-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
  }
  
  .confirmation-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .confirm-button, .cancel-button {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .confirm-button {
    background-color: #28a745;
    color: white;
  }
  
  .cancel-button {
    background-color: #dc3545;
    color: white;
  }
  
  @media (max-width: 480px) {
    .change-password-container {
      padding: 15px;
      width: 90%;
    }
  
    .form-control {
      font-size: 16px;
    }
  }