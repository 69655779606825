.promotion-container {
    width: 100%;
    height: 92vh;
    display: flex;
    flex-direction: column;
    opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  animation: fadeIn 0.5s ease forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

  .promotion-header{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #f9f9f9;
  }
  
  .promotion-title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #3e3e3e; 
  }
  
  .promotion-description {
    font-size: 16px;
    margin-bottom: 20px;
    color: #3e3e3e;
  }
  
  .coupon-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

  }
  
  .coupon-input {
    width: 60%;
    padding: 10px;
    border: 1px solid #555;
    border-radius: 5px;
    margin-right: 5px;
    font-size: 14px;
    text-align: center;
    background-color: #d8dde3;
    color: #414040;
  }
  
  .copy-button {
    background-color: #555; /* Darker button */
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;
  }
  
  .copy-button:hover {
    background-color: #777; /* Slightly lighter on hover */
  }
  
  .copy-message {
    margin-top: 10px;
    color: #28a745; /* Green color for success message */
    font-size: 14px;
    font-weight: bold;
  }
  