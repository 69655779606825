.ship-with-us-header{
    font-size: 2rem;
    font-weight: bold;
    height: 40vh;
    background-color: darkslategray;
    display: flex;
    justify-content: center;
    align-items: center;
}


.ship-with-us-header p{
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 0.5rem;
    color: #b5b5b5;
}