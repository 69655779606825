.contact-home{
    width: 100%;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../assets/images/homesections/megaphone-img.webp);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: grayscale(100%);

}

.contact-home h1{
    font-size: 2rem;
    color: #ffffff;
}


.contact-submain{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    padding-top: 6rem;
    padding-bottom: 6rem;

}

.contact-query{
    height: 100vh;
    width: 65%;
 

}

.contact-bar{
    border-top: 5px solid #0a617a;
    width: 4rem;
}

.contact-query h1{
    color: #0d1828;
}

.contact-query p{
    color: #0d1828;
}

.contact-container{
    width: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px;
    background-color: #02111B;
    border-radius: 20px;

}


.contact-text{
    width: 100%;
    color: white;
    text-align: center;
    padding: 10px;
}


.boxes{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
    padding: 10px;
    cursor: pointer;
}

.first-box,
.second-box,
.third-box,
.fourth-box{
    width: 30%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.first-box:hover,
.second-box:hover,
.third-box:hover,
.fourth-box:hover{
    transform: scale(1.1);
}


@media screen and (max-width: 900px) {
    .contact-submain{
        height: 80vh;
    }


    .contact-container{
        width: 85%;
    }   

    .contact-query{
        width: 80%;

    
    }

}


@media (min-width: 767px) and (max-width: 830px){
    .contact-query{
        height: 25vh;
    
     
    
    }
    
}

@media (min-width: 830px) and (max-width: 1025px){
    .contact-submain{
        height: 50vh;
    }
}


@media(max-height: 500px) {

    .contact-submain{
        height: 100vh;
    }


    .contact-container{
        width: 85%;
    }   

    .contact-query{
        width: 80%;

    
    }
}


@media(max-width: 376px) {
    .contact-query{
        padding: 2rem;
    }

    .contact-submain{
        margin-top: 2rem;
        padding-bottom: 12rem;
    
    }
}
