.admin-chat-container {
    display: flex;
    height: 100vh;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.36);
    position: relative;
    border-radius: 5px;
    background-color: #f5f5f5;
  }

  .admin-chat-header{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    background-color: #163455d4;
    color: white;
    padding-top: 4rem;
  }
  
  .user-list {
    width: 300px;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    overflow-y: auto;
  }

  .user-list:hover{
    cursor: pointer;
    background-color: #01448b20;
  }
  
  .user {
    padding: 10px;
    cursor: pointer;
  }
  
  .user.active {
    background-color: #013b7920;
  }
  
  .chat-box {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  
  .chat-bubble {
    max-width: 70%;
    padding: 10px;
    margin: 5px 0;
    border-radius: 10px;
    background-color: #e1ffc7;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
  }
  
  .chat-bubble.self {
    background-color: #a7c7ff;
    align-self: flex-end;
  }
  
  .chat-bubble p {
    margin: 0;
    padding: 0;
    color: #333;
  }
  
  .timestamp {
    font-size: 10px;
    color: #666;
    align-self: flex-end;
    margin-top: 5px;
  }
  
  .chat-input {
    display: flex;
    padding: 10px;
    background-color: #f1f1f1;
    border-top: 1px solid #ddd;
  }
  
  .chat-input input {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 5px;
  }
  
  .chat-input button {
    padding: 10px 20px;
    margin-left: 10px;
    border: none;
    border-radius: 5px;
    background-color: #01448bd4;
    color: white;
  }

  .split{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  