@import url('https://fonts.googleapis.com/css2?family=Butterfly+Kids&family=Edu+NSW+ACT+Foundation:wght@400..700&family=Great+Vibes&family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&family=Stick&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Butterfly+Kids&family=Edu+NSW+ACT+Foundation:wght@400..700&family=Great+Vibes&family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&family=Sacramento&family=Stick&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&family=Edu+NSW+ACT+Foundation:wght@400..700&family=Ingrid+Darling&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Mobile  Navbar */
.home-section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(./assets/images/loneguy-landscape.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* border-bottom: 6px solid #db9832; */
  background-color: #02111B;
}

.homesection-overlay{
  width: 100%;
  height: 100vh;
  position: absolute;
  background-image: linear-gradient(to right, #040a11, #0d182849);

}


.home-section .centered-items{
  width: 85%;
  color: #FCFCFC;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 1;
}


.centered-texts{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 100;
  height: 55vh;
  width: 60%;
  gap: 10px;
  margin-top: 2.5rem;
}

.centered-texts h1{
  font-size: 44px;
  margin-bottom: 1px;
  line-height: 48px;
  font-weight: 600;
}

.centered-texts p{
  line-height: 24px;
}


.home-section .input-search-items{
  width: 90%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  height: 2.4rem;
  display: none;

}


.home-section .input-search-items form{
  width: 100%;
  height: 2.4rem;
  display: flex;


}

.home-section .input-search-items button{
  width: 15%;
  height: 100%;
  background-color: #02111B;
  border: none;
  cursor: pointer;
  color: #FCFCFC;
}

.home-section .input-search-items input{
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.35);
  padding-left: 10px;


}

.readmore-direction {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: auto;
  background-color: rgb(255, 255, 255);
}

.readmore-direction h2 {
  margin-bottom: 10px;
  font-size: 24px;
  color: #0d1828;
}

.intro-button{
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  
}

.readmore-direction p {
  margin-bottom: 20px;
  font-size: 16px;
  color: #555;
}

.readmore-button {
  padding: 10px 20px;
  font-size: 16px;
  color: rgb(255, 255, 255);
  background-color: #007BFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.readmore-button:hover {
  background-color: #0056b3;
}


.home-section .input-search-items input:focus{
  border: 1px solid skyblue;
}


/* the intro section */
.intro-section{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  font-size: larger;
  color: rgb(255, 255, 255);

}


.intro-inner{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 30%;
  border: 2px solid #db9832;
  border-radius: 20px;
  font-weight: 600;
  font-style: normal;

}


.intro-inner h1{
    color: #fdd7a1;

}


.animated-text {
  width: 100%;
  height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50px);
  transition: opacity 0.5s, transform 0.5s;
  margin-top: 5rem;

}

.animated-text.in-view {
  opacity: 1;
  transform: translateY(0);
}

.animated-text.in-view h1 {
  animation: growText 5s ease forwards;
  white-space: nowrap;
  color: rgb(255, 255, 255);
  border: 1px solid #db9832;
}

@keyframes growText {
  0% {
    font-size: 1rem;
  }
  100% {
    font-size: 2rem;
  }
}



.read-more{
  display: flex;
  width: 100%;
  height: 4rem;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  animation: gently 5s infinite;

}

.r-more{
  text-decoration: none;
  color: #01229b;
}


@keyframes gently {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }}


.read-more:hover{
  color: #0056b3;
}


.span{
  color: #357680;
  text-decoration: none;
}

.span:hover{
  color: #429aa7;
}



.illustration-section{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 130vh;
  background-color: #0d1828;
  border-bottom: 6px solid #4f90aa;
  color: white;
  padding-top: 3rem;

}


.illustration-headline{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vh;
  background-color: #02111B;
  color: white;
  font-size: 1.5rem;
  border-bottom: 2px solid #FA9101;


}


.illustration-inner{
  background-image: url(../src/assets/illustrator/illustration-img.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 90%;
  height: 90vh;
  margin-top: 5rem;
  margin-bottom: 5rem;

}


.call-to-action-section{
  height: 130vh;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;
  gap: 10px;
}


.call-to-action-section h1{
  font-size: 2.5rem;
  color: #17304E;
}

.call-to-action-section button{
  padding: 20px;
  border: 5px;
  box-shadow: inset 0 0 0 2px #2c2c2c38;
  border: none;
  background-color: #31729c;
  color: white;
  border-radius: 5px;
}

.call-to-action-section button:hover{
  background-color: #02111B;
}


.blurb{
  display: flex;
}


.blurb img{
  width: 2.2rem;
  height: 2.2rem;
  object-fit: contain;
}


/* Mobile  Navbar */
@media screen and (max-width: 767px) {
.animated-text {
  height: 20vh;
  width: 20%;
  margin: 0 auto;
  position: relative;
  top: 70px;

}

.animated-text h1{
  font-size: 6px;
  text-align: center;
}


  .non-animated-text{
    width: 100%;
    height: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    line-height: 2px;

  }



.home-section .centered-items{
  width: 85%;

}

.centered-texts{
  width: 100%;

}

.centered-texts h1{
  font-size: 32px;
  margin-bottom: 1px;
  letter-spacing: 1px;
  line-height: 38px;
  font-weight: 600;


}

.centered-texts p{
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;

}

.home-section {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0)), url(./assets/images/loneguy-portait.jpg);
  height: 100vh;

}

.homesection-overlay{
  height: 100vh;
}

.home-section .input-search-items{
  width: 100%;
  display: block;

}

.home-section .input-search-items form{
  width: 100%;

}

.process-spining-center{
  display: none;

}

/* the intro section */
.intro-section{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: small;
}


.intro-inner{
  width: 80%;
  height: 20%;
  padding: 20px;
  text-align: center;

}


.read-more{
  padding-top: 10px;
  height: 6rem;

}

.illustration-section{
  height: 120vh;
 
}


.illustration-section h2{
  font-size: 1.5rem;
}

.call-to-action-section{
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 90%;
  height: 80vh;

}


.call-to-action-section h1{
  font-size: 1.3rem;

}

.call-to-action-section button{
  padding: 22px;
}


.call-to-action-section button{
  font-weight: 500;
}

.quoto{
  font-size: 8px;
}
}


/* smaller tablet  */
@media (min-width: 767px) and (max-width: 830px) {  /* smaller tablet  */
    .centered-texts{
      width: 100%;
    
    }

    .centered-texts h1{
      font-size: 40px;
      margin-bottom: 1px;
      width: 100%;
    
    }
    
    .centered-texts p{
      line-height: 34px;
      font-size: 22px;
      font-weight: 100;
    
    }

    .animated-text {
      height: 40vh;
      width: 40%;
      margin: 0 auto;
      position: relative;
      top: 74px;
    
    }

  
    .home-section .input-search-items{
      width: 80%;
      height: 2.8rem;
      display: block;
    
    }

    .call-to-action-section{
      width: 80%;
      }
      
      .call-to-action-section button{
        font-weight: 500;
      }

  }



  
/* tablet  */
@media (min-width: 830px) and (max-width: 1025px) {  /* tablet  */

  .home-section .input-search-items{
    display: block;
    height:4rem;
  
  }

  .home-section .input-search-items input{

    height:4rem;
  
  }

  .home-section .input-search-items button{

    height:4rem;
  
  }


  .animated-text {
    height: 70vh;
    width: 100%;
    margin: 0 auto;
    position: relative;
    top: 70px;
  
  }
  
  .animated-text h1{
    font-size: 12px;
    text-align: center;
  }
  
  

  
    .non-animated-text{
      width: 100%;
      height: 15vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      line-height: 2px;
  
    }
  

  .home-section .centered-items{
    width: 80%;
  
  }
  
  .centered-texts{
    width: 100%;
  
  }
  
  .centered-texts h1{
    font-size: 64px;
    margin-bottom: 1px;
  
  }
  
  .centered-texts p{
    line-height: 40px;
    font-size: 30px;
    font-weight: 200;
  
  }
  
  .home-section {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0)),
}

.call-to-action-section{
  width: 80%;
  }

  .call-to-action-section button{
    font-weight: 500;
  }
  
}

@media(max-height: 500px) {
  .home-section {
    height: 100vh;

  }

  .homesection-overlay{
    height: 100vh;

  }

    .centered-items{
      margin-bottom: -5px;
  }


  .centered-texts{
    width: 100%;
    height: 30vh;
    margin-bottom: -20px;
  
  
  }

  .centered-texts h1{
    font-size: 30px;
    width: 100%;
    margin-bottom: 0;
  
  }
  
  .centered-texts p{
    line-height: 18px;
    font-size: 16px;
    font-weight: 100;
  
  }

  .animated-text {
    height: 40vh;
    width: 40%;
    margin: 0 auto;
    position: relative;
    top: 74px;
  
  }
  .home-section .input-search-items{
    display: block;
    height:2.2rem;

  
  }

  .home-section .input-search-items input{

    height:2.2rem;
  
  }

  .home-section .input-search-items button{

    height:2.2rem;
  
  }


.call-to-action-section{
width: 80%;
}

.call-to-action-section button{
  font-weight: 500;
}

}


@media only screen and (max-height: 376px) {
  .home-section .input-search-items{

    position: relative;
    top: 1rem;
  
  }

  .call-to-action-section button{
    font-weight: 500;
  }
}
