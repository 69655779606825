.orders-container {
    padding: 20px;
    background-color: #f8f9fa;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  animation: fadeIn 0.5s ease forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
  
  .filters-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    gap: 15px;
  }
  
  .search-div { 
    display: flex;
    align-items: center;
    flex-grow: 1;
    max-width: 400px;
  }
  
  .search-input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px 0 0 4px;
    width: 100%;
  }
  
  .search-button {
    padding: 10px 20px;
    background-color: #15445f;
    color: #fff;
    border: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .search-button:hover {
    background-color: #0056b3;
  }

  .status-dropdown  {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .status-dropdown select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    max-width: 200px;
    flex-grow: 1;
  }
  
  .orders-info {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .order-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .order-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .info {
    font-size: 16px;
    color: #333;
  }
  
  .info span {
    font-weight: bold;
    color: #15445f;
  }
  
  .no-orders {
    grid-column: 1 / -1;
    text-align: center;
    font-size: 18px;
    color: #777;
    padding: 20px;
    background-color: #d8dde3;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 768px) {
    .filters-section {
      flex-direction: column;
      align-items: stretch;
    }
  
    .search-div {
      width: 100%;
    }
  
    .status-dropdown {
      width: 100%;
    }
  }
  