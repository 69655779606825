.not-signed-in-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0; /* Light grey background for the entire container */
}

.notification-box {
    text-align: center;
}

.not-signed-in-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333; /* Dark grey text for the title */
}

.sign-in-link {
    font-size: 1.2rem;
    color: #007bff; /* Blue color for the link */
    cursor: pointer;
    text-decoration: underline;
}

.sign-in-link:hover {
    color: #0056b3; /* Darker blue on hover */
}
