/* styles/ReadMore.css */
.readmoreOuter{
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;

}

.readmore-container {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    background-color: #f9f9f9;
    width: 83%;
    flex-wrap: wrap;
    position: relative;
    border-left: 4px solid #3f8893;
    
    
  }
  
  .readmore-column {
    display: flex;
    align-items: center;
    gap: 50px;
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    z-index: 1;
    padding: 0;
  }
  
  .readmore-column h2 {
    font-size: 36px;
    color: #2e4156;
    padding: -35px; 
    position: relative;
    top: 1rem;
  }
  
  .readmore-column p {
    font-size: 16px;
    color: #555;
    text-align: left;
    position: relative;
    bottom: 0.3rem;

  }
  
  .readmore-button {
    padding: 12px 25px;
    font-size: 16px;
    color: #fff;
    background-color: #3f8893;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;
    font-family: 'Open Sans', sans-serif;
    position: relative;
    top: 1rem;
}

#changetext{
    padding: 0;
}

.readmore-button:hover {
  background-color: #2c6d77;
}

.button-icon-container {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.button-icon {
  width: 16px;
  height: 16px;
}

  
  @media (max-width: 768px) {
    .readmoreOuter{
     padding: 0;
    
    }

    .readmore-container {
      background-image: url(../assets/images/homesections/Fix_laptop_keyboard_not_working.jpeg);
      background-size: cover;
      background-attachment: fixed;
      background-position: center;
      position: relative; /* Ensure the pseudo-element positions correctly */
      align-items: center;
      width: 100%;
      padding-bottom: 70px;
      border: none;
      border-radius: 0;
     
    }
    
  
    .readmore-container::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
      z-index: 0; /* Ensure overlay is below the content */
    }

    .readmore-column {
        padding: 0;
        padding-bottom: 3rem;
    }


    .readmore-column h2 {
        color: #ffffff;
        font-size: 20px;
      }
      
      .readmore-column p {
        color: #ffffff;

      }

      #changetext{
        position: relative;
        bottom: 2.1rem;
      }
      
    
      .readmore-button {
        padding: 1px 15px;
        position: relative;
        top: 15.5rem;
        right: 10.5rem;
        height: 2.5rem;
        font-size: 13px;
      }

      
      .readmoreOuter{
        padding-top: 0;
        padding-bottom: 0;
      
      }
      
}

@media (min-width: 767px) and (max-width: 830px) { 

  .readmore-container {
    width: 80%;
    position: relative;
    bottom: 20px;
  }

  .readmoreOuter{
    padding-bottom: 5rem;
  }
}