.create-tracking-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-family: "Poppins",sans-serif;
}

.tracking-home-section{
    position: relative;
    height: 20vh;
    width: 100%;
    border-bottom: 2px solid #FA9101;

  }
  
  .tracking-home-section video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    
  }

  
  .tracking-home-section .tracking-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0a0d2b8c
  }
  
 .tracking-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    
  }


  .dashboarddivs{
    background: #ffffff;
    width: 70%;
    height: 100vh;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;

    
  }
  
.generatedid{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #eff6fc;
    padding: 5px;
}

.generatedid svg:hover{
    cursor: pointer;
    transform: scale(1.1);
    color: orange;
}


.create-tracking-container .tracking-form-section {
    width: 100%;
    padding-top: 1.2rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
}

.create-tracking-container .tracking-form-section form {
    display: flex;
    flex-direction: column;
    width: 95%;
    height: 100%;
    justify-content: center;
    padding: 30px;
    gap: 20px;
    
    
}

.generate-btn{
    width: 30%;
    padding: 10px 20px;
    background-color: #1b2634;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.create-tracking-container .tracking-form-section input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    width: 100%;
    border-radius: 4px;
}

.create-tracking-container select {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    width: 100%;
    border-radius: 4px;
}


.create-tracking-container .tracking-form-section .tracking-submit-button {
    padding: 10px 20px;
    background-color: #FA9101;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
}

.create-tracking-container .tracking-form-section button:hover {
    background-color: #1831a4;
}


.tracking-info-section {
    display: flex;   
    border-top: 1px solid orange ;
    gap: 50px;
    padding-top: 4rem;
    flex-wrap: wrap;
}

.tracking-search-section {
    display: flex;
    margin-top: 1.5rem;
    margin-bottom: 20px;
}

.tracking-search-section input {
    padding: 10px;
    width: 85%;
    max-width: 400px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
}


.t-info-heading {
    color: #484a19;
}

.tracking-card {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    margin: 10px 0;
    width: 17rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}


.tracking-edit-btn{
    background-color: #1b2634;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 10px;
    margin-top: 10px;

}

.edit-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}

.edit-box p {
    margin: 0;
    flex: 1;
    color: #19314a;
    font-size: 12px;
}

.edit-box img {
    cursor: pointer;
    margin-left: 10px;
}

@media screen and (max-width: 908px) {

    .tracking-info-section {
        flex-direction: column;
    
    }
    .create-tracking-container .tracking-form-section form {
        width: 84%;
        gap: 10px;
        
    }

    .tracking-card {
        width: 90%;
    }

    .create-tracking-container .tracking-form-section input {
        width: 90%;
        padding: 10px;

    }

    .tracking-search-section input {
        width: 100%;

    }

    .generate-btn{
        width: 50%;
    }
    
}

