  .process-main{
    display: flex;
    flex: 0.4;
    padding-top: 7rem;
    padding-bottom: 2rem;
    
  }

  .shipping-process {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

  }

  .process-step {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    width: 90%;
    margin: 5px 0;
  }

  .process-step.left {
    flex-direction: row-reverse;
  }

  .heading{
    width: 65%;
    padding: 10px;
    display: flex;
  }

  .heading-right{
    justify-content: flex-end;

  }

  .s-process-heading{
    width: 30%;
    display: flex;
    padding-left: 80px;
    flex-direction: column;
  }
  
  .s-process-heading h2{
    font-size: 44px;
  }
  
  .circle {
    width: 60px;
    height: 60px;
    background-color: #5D737E;
    box-shadow: 0 5px 10px rgba(118, 119, 121, 0.3);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
    z-index: 1;
    position: relative;
    border: 5px solid #5D737E;
  }

  .circle:hover{
    border: 2px solid #5D737E;
    transition: border 0.5s ease-in-out;
  }

  .circle img{
    width: 30px;
    height: 30px;
  }

  .process-step:last-of-type .circle {
    background-color: #162945;
  }
  
  .underline{
    width: 20%;
    background-color: #dfdfdf;
    height: 4px;
    border-radius: 30px;
    position: relative;
    bottom: 12px;
  }

  .text {
    margin: 0 20px;
    font-size: 18px;
    width: 200px;
  }

  .inner-info{
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #f8f8f8;
    box-shadow: 0 5px 10px rgba(118, 119, 121, 0.3);
    padding-left: 10px;
  }

  .inner-info h3{
    color: #3d677f;
    font-weight: 600;
    position: relative;
    top: 0.9rem;
    font-size: 14px;
  }

  .inner-info p{
    color: black;
    font-size: 11px;
    font-family: 'Open Sans', sans-serif;
    position: relative;
    line-height: 18px;
  }

  .line {
    width: 1px;
    height: 210px;
    background-color: #ffffff;
    position: absolute;
    z-index: 0;
  }
  
  .scroll-indicator-lines {
    width: 100%;
    height: 0%;
    transition: height 0.5s ease-in-out;
    border: 1.1px solid #3d677f;

  }
  
  .line-right {
    right: 120px;
    height: 210px;
    transform: rotate(90deg);
  }
  
  .line-left {
    left: 120px;
    height: 210px;
    transform: rotate(-90deg);
  }

  @media (max-width: 768px) {
  .process-main{
      display: flex;
      flex-direction: column;
    }

  .process-step {
        width: 90%;
        margin: 20px 0;

      }
      
      .process-step.left {
        flex-direction: row-reverse;
      }
    
      .heading{
        width: 65%;
        display: flex;
        /* background-color: rgb(229, 179, 179); */
      }

    .s-process-heading{
        width: 80%;
        justify-content: flex-start;
        padding-left: 0;

    
      }

    .s-process-heading h2{
        font-size: 30px;
        width: 80%;
        position: relative;
        left: 30px;

      }


      .circle {
        width: 75px;
        height: 75px;

      }
      
      .circle img{
        width: 30px;
        height: 30px;
      }



      .inner-info{
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 40%;
        padding-left: 20px;

      }
    
      .inner-info h3{
        color: #3d677f;
        font-weight: 600;
        font-size: 18px;
        position: relative;
        right: 15px;
      }
    
      .inner-info p{
        color: black;
        font-size: 10px;
        font-family: 'Open Sans', sans-serif;
        line-height: 16px;
        position: relative;
        right: 15px;
      }
      
      .scroll-indicator-lines {
        display: none;
      }
      
      /* .line-right {
        left: 150px;
        top: 20px;
        height: 410px;
        transform: rotate(45deg);
      }
      
      .line-left {
        right: 150px;
        top: 26px;
        height: 410px;
        transform: rotate(-45deg);
      } */

      .underline{
        position: relative;
        left: 30px;
    
      } 

      
  }


  
@media (min-width: 767px) and (max-width: 830px) {  /* smaller tablet  */

  .process-step {
      width: 80%;
    }


  .scroll-indicator-lines {
    display: none;
  }

}

/* tablet  */
@media (min-width: 830px) and (max-width: 1025px) {  /* tablet  */
  .process-step {
      width: 80%;
    }

  .line-right {
      left: 370px;
      top: -70px;
      height: 650px;
      transform: rotate(65deg);
    }

  .line-left {
    right: 370px;
    top: -55px;
    height: 650px;
    transform: rotate(-67deg);
  }

  .scroll-indicator-lines {
    display: none;
  }
  
  }



  @media(max-height: 500px) {
    .line-right {
      left: 150px;
      top: 2px;
      height: 450px;
      transform: rotate(45deg);
    }
    
    .line-left {
      right: 150px;
      top: 2px;
      height: 450px;
      transform: rotate(-45deg);
    }

  }

