.article-details-container {
  padding: 20px;
  padding-top: 5rem;
}

.article-details-container p {
  color:darkmagenta;
}

.article-details-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  margin-bottom: 20px;
}

.article-content {
  font-size: 16px;

}


.main-article-content {
  font-size: 16px;
  padding: 20px;
}

.main-article-content p{
  font-size: 20px;
  color: darkslategray;
}