/* Login styles */

.login-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: 'Open Sans', sans-serif;
  }

  .login-form-pad{
    display: flex;
    align-items: center;
    width: 80%;
    height: 80%;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.5s ease, transform 0.5s ease;
    animation: fadeIn 0.5s ease forwards;
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }


  .login-form {
    width: 50%;
    height: 90%;
    display: flex;
    justify-content: center;
    padding-left: 3rem;
    flex-direction: column;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }


  .login-image-container {
    width: 50%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-right-radius: 20px;
  
}

.login-form input{
  width: 80%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-family: 'Open Sans', sans-serif;
}


.login-form input:focus {
    outline: none;
    border: 1px solid #0d4d5e;
    transition: 0.3s ease-in-out all ;
  }


.login-button {
  width: 84%;
  padding: 10px;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  background-color: #5f7f88;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
}


.login-button:hover {
  background-color: #0d4d5e;
}


.divider {
  margin: 10px 0;
  text-align: center;
  width: 100%;
  font-weight: bold;
  font-family: "Outfit", sans-serif;
}

.social-button {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.google-button {
  background-color: #F0F5F7;
  color: rgb(52, 52, 52);
  border: 2px solid #393939;
  border-radius: 10px;
  width: 84%;
}

.google-button:hover {
  background-color: #EAF3F8;
}


.intersection{
  display: flex;
  align-items: center;
  width: 84%;
  justify-content: center;

}

.intersection p{
  color: black;
}

.login-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    filter: grayscale(100%);
}

.login-image-container img:hover {
    filter: grayscale(10%);
}

.noregtag{
  color: #000000;
}


.noreg{
  text-decoration: none;
  color: #1b5cdf;
}

/* Media Queries */

@media screen and (max-width: 900px) {
    .login-form-pad{
    width: 90%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: grayscale(30%);
    z-index: 100;
    flex-direction: column-reverse;
    justify-content: center;
    
    
      }


    .login-form {
        width: 90%;
        height: 80%;
        border-radius: 0px;

    }

    .login-form h2{ 
        color: white;
    }
    

    .login-image-container {
        width: 90%;
        height: 0.5rem;
        border-radius: 0px;
    }

    .login-image-container img {
      border-radius: 0px;
  }


    .login-button {

        background-color: #0c2f39;

      }

}