.profile-avatar{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #f9f9f9;
    margin-bottom: 2rem;
    margin-left: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}

.profile-avatar h1{
    color: #16425b;
    font-weight: bold;
}

#user-dash-tabs {

    justify-content: start;
  }


.inner-tab{
    margin-left: 15px;
}

.dash-hamburger {
    position: absolute;
    top: 20px;
    
  }
