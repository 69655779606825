/* Login.css */

.register-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    padding-top: 5rem;
    padding-bottom: 5rem;

  }

  .register-form-pad{
    display: flex;
    align-items: center;
    width: 90%;
    height: 100%;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.5s ease, transform 0.5s ease;
    animation: fadeIn 0.5s ease forwards;
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .name-container {
    display: flex;
    gap: 10px;
    width: 85%;
  }
  
  .phone-container {
    display: flex;
    gap: 4px;

  }


  .register-title{
    margin-bottom: 1rem;
  }
  
  .c-code{
    width: 30%;
    height: 38px;
    margin-bottom: 12px;
    background-color: rgb(255, 255, 255);
    
  }
  
  .agreement-section {
    margin-top: 5px;
    display: flex;
    align-items: center;
  }

  .register-title{
    width: 100%;
  }

  .register-form {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding-left: 3rem;

  }

  .inner{
    width: 100%;
    max-height: 70%;
    margin: 0 auto;
    overflow-x: hidden;
  }

  /* For WebKit browsers (Chrome, Safari, etc.) */
.inner::-webkit-scrollbar {
  width: 3px; /* Thin scrollbar width */
}

.inner::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}

.inner::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}

.inner::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color when hovering over the scrollbar thumb */
}

/* For Firefox */
.inner {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #888 #f1f1f1; /* Thumb color and track color */
}


  .register-image-container {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-right-radius: 20px;
  
}

.register-form input{
  width: 80%;
  padding: 11px;
  margin-bottom: 20px;
  border: 1px solid #d5d5d5;
  border-radius: 3px;
}

.register-form input:focus {
  outline: none;
  border: 1px solid #0d4d5e;
  transition: 0.3s ease-in-out all ;
}

.register-button {
  width: 85%;
  padding: 10px;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  background-color: #5f7f88;
  cursor: pointer;

}

.register-button:hover {
  background-color: #0d4d5e;
}

.phone-container {
  width: 85%;
}

.register-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    filter: grayscale(100%);
}

.register-image-container img:hover {
    filter: grayscale(10%);
}


.agreement-section{
  width: 83%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 15px;
  gap: 5px;
}

.agreement-section input{
  width: 4%;

}

.agreement-section label{
  color: #000000;
  font-size: 13px;
  font-weight: 200;
  width: 96%;
}

.terms-link{
  color: #3a43ef;
  font-size: 13px;
  font-weight: 200;
  text-decoration: none;

}

.select-container {
  display: flex;
  gap: 20px;
  width: 80%;

}

.c-code {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex: 1;

}


.phone-container input {
  width: 65%;
}

@media screen and (max-width: 900px) {
    .register-form-pad{
    width: 100%;
    height: 70vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: grayscale(30%);
  
    }


    .register-form h2{ 
        color: rgb(0, 0, 0);

    }

    .name-container {
      width: 87%;
    }

    .phone-container {
      width: 87%;
    }



    .register-form {
        width: 100%;
        border-radius: 0%;

    }

    .register-button {
        background-color: #0c2f39;

      }

      .register-image-container {
        display: none;
      
    }
    
}