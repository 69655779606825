.profile-page {
    display: flex;
    flex-direction: column;
    opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  animation: fadeIn 0.5s ease forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
  
  .delete-account{
    cursor: pointer;
    color: red;
  }

  .confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .confirmation-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    flex-direction: column;
    display: flex;
    gap: 20px;

  }

  .confirmation-content p{
    font-size: 20px;
    color: #000000;
  }


  .confirmation-content .inner {
    display: flex;
    width: 100%;
    gap: 20px;
    justify-content: center;
  }
  
  .confirmation-content .confirm {
    background-color: #16425b;
    color: white;
  }

  
  .prof-tab-section {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    padding: 30px;
  }

  .prof-tab-section h2{
    position: relative;
    left: 10px;
  }
  
  .prof-tab-section button {
    padding: 10px;
    border: none;
    background-color: #f8f9fa;
    cursor: pointer;
    font-weight: bold;
  
  }
  
  .prof-tab-section .active {
    border-bottom: 3px solid #f3f3f3;
  }
  
  .prof-tab-section {
    margin-top: 20px;
  }
  
  .profile-form input, .profile-form-inner input {
    width: calc(50% - 20px);
    margin: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    background-color: #e9edef;
  }

  .user-flag-container {
    width: calc(50% - 20px);
    margin: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #ddd;
    background-color: #e9edef;
    line-height: 0;
    display: flex;
    align-items: center;
    gap: 10px;

  }

  .flag-name{
    color: #00000061;
    font-size: 14px;
  }

  
  .flag-container p{
    line-height: 0;
  }

  .profile-form select{
    width: 50%;
    margin: 10px;
    padding: 10px;
    border: 1px solid #ddd;
  }



  .profile-form-inner{
    display: flex;
    gap: 20px;
    width: 80%;

  }

  .user-tab-section{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px;

  }

  .user-tab-section .active {
    border-bottom: 2px solid #3d677f;
  }
  
  .user-tab-section button {
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.934);
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    margin-top: 20px;
  }

  .user-tab-section button .active {
    border-bottom: 2px solid #3d677f;

  }

  .support-inner{
    display: flex;
    gap: 10px;
    align-items: center;
    padding-left: 12px;
  }

  .support-inner p, a{
    font-size: 16px;
  }
  
  hr {
    margin: 10px 0;
  }
  
  
  #user-btn{
    background-color: #15445f;
    color: rgb(255, 255, 255);
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    position: relative;
    left: 10px;
    width: 15rem;
  }

  #user-btn:hover{
    background-color: #196e86;
  }

  @media screen and (max-width: 767px){
    .profile-form-inner{
        flex-direction: column;
        width: 100%;
      
      }

      .prof-tab-section {
        padding: 10px;
        width: 90%;
      }

      .greeting{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        position: relative;
        margin: 0 auto;
      }

      .profile-form input, .profile-form-inner input {
        width: calc(100% - 20px);
        margin: 10px;
        padding: 10px;
        border: 1px solid #ddd;
      }

      .flag-container {
        width: calc(100% - 20px);
        padding: 8px;

      }

    
      .profile-form select{
        width: 100%;
        margin: 10px;
        padding: 10px;
        border: 1px solid #ddd;
      }
    
      .support-inner p, a{
        font-size: 14px;
      }
  }