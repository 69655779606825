.stats-container {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  background-color: #ffffff;
  
}

.stats-content {
  display: flex;
  justify-content: space-between;
}

.stats-item {
  width: 20%;
  padding: 10px;
  background-color: #02111B;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
 
}

.cumulative-inner{
  width: 100%;
}

.cumulative-section {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 10px;
  margin-top: 3rem;
}

.table-container {
  max-height: 40vh;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;

}

/* Container for the scrollable report */
.report-scroll-container {
  max-height: 30vh;
  overflow-y: auto;
  padding: 2px;
  border: 1px solid #8fcaf8;
  border-radius: 5px;
  margin-bottom: 5rem;
}

/* Table styling */
.cumulative-report-table {
  width: 100%;
  border-collapse: collapse;
  font-family: 'Rubik', sans-serif;
}

.cumulative-report-table th, .cumulative-report-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.cumulative-report-table th {
  background-color: #dcf2fa;
  font-weight: 600;
  text-align: center;
}

.cumulative-report-table td {
  vertical-align: top;
}

.cumulative-report-table td .stt {
  margin-bottom: 8px;
}

.cumulative-report-table td .stt p {
  margin: 0;
  color: #1b2634;
}

.cumulative-report-table td .stt p span {
  color: #007bff;
}


.cumulative-report-button {
  background-color: #143256;
  color: #f9f9f9;
  border: 1px solid #f9f9f9;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s;
}

.stats-item h3 {
  font-size: 16px;
  margin-bottom: 5px;
}

.stats-item p {
  font-size: 14px;
  color: #eaeaea;
}

.stt{
  display: flex;
  flex-direction: column;

}


.stt label{
  font-weight: bold;
}


.chart-container {
  width: 100%;
  height: auto;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.chart-item {
  flex: 1;
}

@media screen and (max-width: 900px) {
  
  .stats-item {
    padding: 5px;
    width: 40%;
  }

  .stats-item p {
    font-size: 10px;
    font-weight: bold;
    color: #f9f9f9;
  }

  .stats-container {
    width: 98%;
  }

  .cumulative-inner {
    flex-direction: column;
    justify-content: center;
  }

  .chart-container {
    flex-direction: column;
    width: 100%;
  }

  .chart-item{
    width: 100%;
  }

  .hamburger {
    display: block;
  }

  .stats-content {
    margin-left: 0;
  }
}
