.animated-text {
    text-align: center;
    margin: 0 auto;
    height: 50vh;
    padding-top: 5rem;


  }
  
  .animated-text h1 {
    transition: font-size 0.1s ease-out;
  }
  

  @media screen and (max-width: 900px) {
    .animated-text {
        height: 25vh;
        width: 100%;
        padding-top: 2px;
        padding-bottom: 20px;
    
      }
}