.main-schedule-container{
  width: 100%;
}

.schedule-container {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 60vh;

}


.schedule-home-section{
  position: relative;
  height: 50vh;
  overflow: hidden;
  border-bottom: 2px solid #FA9101;
}

.schedule-home-section video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  
  

}


.schedule-home-section .schedule-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1f3146c3;
}

.schedule-content {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;

  
}


.schedule-container .search-input {
  padding: 25px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}


.schedule-container .search-input input{
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #456082;
  background: none;
}


.schedule-container .search-input input:enabled{
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #456082;
  background: none;
}


.schedule-container .search-input img{
  color: white;

}

.schedule-container  .search-input img:hover{
  cursor: pointer;
  transition: 0.3s ease-in-out all;
  transform: scale(1.1);

}

.schedule-table {
  width: 100%;
  border-collapse: collapse;
}



.schedule-table th, .schedule-table td {
  padding: 10px;
  border-top: 1px solid #1d2121;
  border-top: none;
  border-left: none;
  border-right: none;
  color: #1b2634;
  
}


.schedule-table th {
  background-color: #152a49;
  padding: 15px;
  border: 1px solid #1d2121;
  color: #ffffff;
  text-align: center;
  
  
}



@media (max-width: 900px) {

    .schedule-table th {
      padding: 10px;

    }

    .schedule-container .search-input {
      padding: 0;
      padding-top: 4rem;
      padding-bottom: 2rem;
    }
  
}


@media(max-width: 376px) {

.schedule-container {
  width: 90%;
  padding-bottom: 5rem;

}

.schedule-table th {
  background-color: #456082;
  padding: 0;
  border: 1px solid #1d2121;
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  
}


.schedule-container .search-input {
  padding: 0;
  padding-top: 4rem;
  padding-bottom: 2rem;
}

}