.process-container .home-section {
    background-image: url(../assets/images/homesections/bg-stretch-1.jpg);
    background-size: cover;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;

}

.process-container h2 {
    color: #fff;
}


.more-info {
    width: 100%;
    text-align: center;
}

.info-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}


.info-box {
    width: 18%;
    box-sizing: border-box;
    margin: 10px;
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    display: flex;
    justify-content: space-evenly;
}


.process-description {
    text-align: left;
    width: 70%;


}

.process-box {
    flex: 1;
    padding: 5px;
}

.process-description p {
    color: black;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
}


.process-container .info-box {
    width: 80%;
    padding: 15px;
    max-height: 200px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
}



.process-container .info-box p {
    font-size: 18px;

}

.process-left-column {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    line-height: 10px;


}

.process-container .suggested-stores {
    padding-top: 5rem;
    padding-bottom: 3rem;
}

.process-container .suggested-stores .suggested-stroes-text {
    text-align: center;
    margin-bottom: 30px;
}


.store-logos {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 10px;
    padding: 10px;

}

.store-logos .boxx {
    box-shadow: 0 0 2px rgba(100, 107, 244, 0.213);
    width: 70%;
    padding: 10px;
    background-color: #f9f9f9;
}

.store-logos img {
    object-fit: contain;

}


.process-container button {
    width: 80%;
    padding: 20px;
    border: none;
    background-color: #e69b2b;
    color: #fff;
    cursor: pointer;
    margin-top: 10px;

}

.process-container button:hover {
    background-color: #ee9c22;
}


/* Media query for responsiveness */
@media screen and (max-width: 900px) {

    .process-container .more-info {
        flex-wrap: nowrap;
        flex-direction: column;
        padding-top: 5rem;

    }

    .process-container .info-box {
        width: 80%;
        padding: 10px;

    }


    .store-logos {
        grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    }


    .process-left-column {
        width: 80%;
        line-height: 30px;
    }

    .process-left-column h1 {
        font-size: 20px;
    }

    .process-left-column p {
        display: none;
    }

    .process-container .info-box p {
        font-size: 12px;

    }

    .process-box img {
        width: 2rem;
        height: 2rem;
    }


    .process-box h3 {
        font-size: 10px;
    }
}