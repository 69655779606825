.tile-main{
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 70vh;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  
}

.tile-heading{
  color: #1b2634;
  font-size: 30px;  
}

.tile-container {
  display: flex;
  gap: 13px;
  padding: 20px;
  justify-items: center;
  align-items: center;
  width: 70%;
  margin-left: 0.5rem;
}

/* Styling for each tile box */
.tile-box {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 80px;
  width: auto;
  flex: 1;
}

/* Hover effect: enlarge slightly and add shadow */
.tile-box:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Responsive tile image inside the box */
.tile-image {
  width: 60%;
  height: auto;
  object-fit: cover;
}

/* Responsive design tweaks */
@media (max-width: 768px) {
  .tile-main{
    height: 100vh;
  }

  .tile-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }

  .tile-box {
    border-radius: 8px;
    height: 80px;
  }

  .tile-image {
    width: 40%;
  }

  .tile-heading{
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .tile-container {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
}