.user-profile-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fffefe;
    border-bottom: 1px solid #ddd;
    padding: 10px;
    border: 1px solid #3d677f;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }

  .user-profile-title-section {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: center;
    color: #3d677f;
  }

  .user-profile-title-section p{
    font-size: 20px;
    color: #a2b0c0;
    font-weight: bold;
  }


  .greeting{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
  
  .back-button {
    background-color: transparent;
    border: none;
    color: #000000;
    cursor: pointer;
  }
  
  .user-icon {
    width: 40px;
    height: 40px;
    background-color: #3d677f;
    color: rgb(255, 255, 255);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  @media screen and (max-width: 768px) {

    .user-profile-navbar {
      flex-direction: column;
      background-color: #3d677f;
      color: white;
    }

    .user-profile-title-section{
      flex-direction: column;
      text-align: center;

    }
    
    .greeting{
      flex-direction: column;
      text-align: center;
    }

    .greeting span,
    .greeting svg,
    .greeting p{
      color: white;
    }

    .greeting .user-icon{
      background-color: white;
      color: #3d677f;
    }
  }