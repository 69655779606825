.quotation-container{
    height: 130vh;
    width: 100%;
    display: flex;
    flex: 1;
    

}

.quotation-text{
    display: flex;
    justify-content: center;
    align-items: center;   
    background-image: url(../assets/images/tyre-img.jpg); 
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    width: 45%;
    
    
}

.quotation-left-text h1{
    font-size: 36px;
    line-height: 43px;
    

}

.quotation-overlay{
    width: 100%;
    height: 130vh;
    position: absolute;
    background-color: #02111b6d;
}

.quotation-calculator{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    color: black;
    

}

.location-select{
    padding: 10px;
    width: 50%;
}

.page-location-select{
    padding: 10px;
    width: 47%;
    position: relative;
    right: 10px;
}

.page-desti-location-select{
    position: relative;
    right: 23px;
    padding: 10px;
    width: 46.6%;
}

.quotation-calculator input{
    width: 100%;
    height: 2rem;
    padding: 3px;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    color: rgb(55, 55, 55);
    border: 1px solid #c4c9bd;

}


.quotation-input-group{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    width: 96%;
    height: 96%;
    box-shadow:  0 0 10px 0 rgba(0, 0, 0, 0.2);
    padding: 12px;
    background-color: #02111B;
    z-index: 1;

}

.quotation-input-group h2{
    font-size: 36px;
    line-height: 48px;
    padding-left: 20px;
}


.query-quote{
    display: flex;
    gap: 10px;
    width: 100%;
    text-decoration: none;
    align-items: center;
    
}



.link-to-pricing-page{
    text-decoration: none;
    color: #f5a208;  


}

.link-to-pricing-page:hover{
    color: #FFA500;
    text-decoration: underline;
}

.quotation-left-text{
    width: 30%;
    height: 95%;
    padding: 20px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.quotation-input-group-top{
    display: flex;
    align-items: center;
    gap: 20px;
    height: 3rem;
    width: 90%;
    padding-left: 20px;
    padding-top: 10px;
}


.quotation-input-group-middle{
    display: flex;
    gap: 20px;
    width: 90%;
    padding-left: 20px;
    padding-top: 10px;
}

.quotation-input-group-middle input{
    padding-left: 10px;

}

.quotation-input-group-middle .kg{
    width: 25%;

}

.quote-input-group-bottom{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 92%;
    height: 20vh;
    padding-left: 20px;

}

.quote-input-group-bottom input{
    width: 45%;
    padding-left: 10px;
}

#calculator{
    background-color: #ffffff;
}

@media screen and (max-width: 767px) {

    .quotation-container{
        flex-direction: column;
        height: 70vh;
        
    
    }
    

    .quotation-input-group{
        width: 92%;
        height: 95%;
        border-radius: 1px;
        padding: 15px;
    
    }


    .quotation-overlay{
        display: none;
     } 


    .quote-input-group-bottom{
        height: 10vh;     
        width: 88%;

    }

    .query-quote p{
        margin-left: 20px;
        font-size: 16px;
    }

    .query-quote a{
        font-size: 16px;

    }

    .quotation-calculator{
        background-color: rgb(255, 255, 255);

    }

    .quotation-input-group h2{
        font-size: 30px;
        padding-left: 20px;
        
    }

    .quote-input-group-bottom input{
        width: 43%;
    }
    

}

@media (min-width: 767px) and (max-width: 830px) {  /* smaller tablet  */
    .quotation-container{
        height: 90vh;
        width: 100%;
        display: flex;
        flex: 1;
    
    }

    .quotation-text{
        width: 40%;
    
    }


    .quotation-left-text{
        height: 85vh;
        width: 35%;
    }

    .quotation-left-text h1{
        font-size: 1.7rem;  
    
    }

    .quotation-left-text p{
        font-size: 14px  
    
    }
    
    
    .quotation-overlay{
        height: 90vh;
        position: absolute;
    }


    .query-quote{
        display: flex;
        gap: 10px;
        width: 100%;
        text-decoration: none;
        align-items: center;
    }

    .query-quote p{
        font-size: 11px;
    }
    
    
    .link-to-pricing-page{
        text-decoration: none;
        color: #FFA500;
        
    
    }

    .quotation-calculator{
        background-color: #ffffff;
    
    }
    
}

/* tablet  */
@media (min-width: 830px) and (max-width: 1025px) {  /* tablet  */
    .quotation-container{
        height: 90vh;
        width: 100%;
        display: flex;
        flex: 1;
        
    
    }

    .quotation-text{
        width: 40%;
    
    }


    
    .quotation-text{
        width: 40%;
    
    }


    .quotation-left-text{
        height: 65vh;
        width: 35%;
    }

    .quotation-left-text h1{
        font-size: 2rem;  
    
    }

    .quotation-overlay{
        height: 90vh;

    }


    .quotation-left-text p{
        font-size: 17px  
    
    }}


    @media(max-height: 500px) {

        .quotation-container {
            height: 150vh;
        }
    
        .quotation-text{
            height: 150vh;
                    
        }
    
        .quotation-left-text{
            height: 150vh;
            width: 35%;

        }
    
        .quotation-left-text h1{
            font-size: 1.7rem; 
         
        
        }
    
        .quotation-left-text p{
            font-size: 14px  
        
        }
    

        .quotation-overlay{
            height: 150vh;

        }
    }