.faq{
    width: 96%;

}

.faq .home h2{
    color: white;
    font-family: 'Rubik', sans-serif;

}

.faq-container {
    width: 80%;
    height: 140vh;
    margin: 0 auto;
    padding: 20px;
    padding-top: 60px;
    
  }

  .faq-container h2{
    font-size: 36px;
  }
  
  .faq-list {
    display: flex;
    flex-direction: column;
  }

  
  .faq-item {
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    animation: appear linear 0.9s;
    animation-timeline: view();
    animation-range: entry 0% cover 40%;
  
  }

  @keyframes appear {
    from {
      opacity: 0;
      scale: 0.9;
    }
    to {
      opacity: 1;
      scale: 1;
    }
  }
  
  .question {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px;
    
    
  }
  
  .question:hover {
    background-color: #f0f0f0;
  }
  
  .answer {
    border-top: 1px solid #ccc;
    background-color: #cfdfe5;
    padding: 20px;
    

  }
  
  .icon {
    font-weight: bold;
  }
  
  .active {
    display: block;
  }
  

  @media screen and (max-width: 900px) {
    .faq-container {
      width: 88%;
      height: auto;

    }

    .faq-container h2{
      font-size: 30px;
    }
  }