
  
  .page-quote-calculator {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    
  }
  
  .input-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .input-group input {
    padding: 8px;
    width: 45%;
  }
  
  .quotation-page-input-group-middle{
    display: flex;
    gap: 10px;
    width: 85%;
    padding: 10px;
    
  }

  .quotation-page-input-group-middle input{
    width: 50%;
    padding: 10px;
    
    
  }

  .quotation-input-group-bottom{
    width: 80%;
    gap: 10px;
    height: 5rem;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  

  .quotation-input-group-bottom input{
    padding: 10px;
    width: 50%;
  }

  .main-home-section {
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0)), url(../assets/images/tyre-img.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-bottom: 6px solid #db9832;
  }

  .quotation-inner{
    width: 100%;
    background-color: #0d1828;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-quotation-input-group{
    width: 80%;
    padding: 10px;


}

  .responsive-tables {
    margin-top: 20px;
    margin: 0 auto;
    width: 80%;
  }
  
  .table-container {
    overflow-x: auto;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
  }

  .responsive-tables {

    padding: 30px;
  }


  @media screen and (max-width: 900px) {

    .quotation-inner{
    height: 60vh;
    }

    .main-quotation-input-group{
      padding: 10px;
      background-color: #0d1828;
      margin: 0 auto;
      width: 90%;

  }

  .quotation-input-group-bottom{

    padding: 15px;
  }

  .quotation-input-group-bottom input{
    width: 45%;
  }

  .main-quotation-input-group input{
    padding: 10px;
  }
    
    }

  