.shipping-calculator-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  animation: fadeIn 0.5s ease forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.skw-inner{
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px;
}

.shipping-calculator {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  width: 95%;
}

.shipping-calculator h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.shipping-calculator p {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input-row {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.input-row label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input-row input,
.input-row select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.query-quote {
  margin-top: 20px;
  text-align: center;
}

.query-quote p {
  font-size: 16px;
  color: #ffffff;
}

.link-to-pricing-page {
  text-decoration: none;
  font-weight: 500;
}

.link-to-pricing-page:hover {
  text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .input-row {
    flex-direction: column;
  }

  .input-row input,
  .input-row select {
    width: 100%;
  }
}
