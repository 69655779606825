.address-box{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 20px;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.5s ease, transform 0.5s ease;
    animation: fadeIn 0.5s ease forwards;
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

.address-inner{
    display: flex;
    padding: 20px;
}

.indie-address-card{
    width: 25%;
    border-radius: 30px;
    height: 65vh;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.address-icon{
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.address-title{
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
  
}

.address-content{
    padding: 20px;
    flex: 1;
}

.address-content p{
    line-height: 24px;
    color: rgb(27, 27, 27);
}

.address-copty-btn{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.address-copty-btn button{
    width: 20%;
    padding: 8px;
    background-color: #16425b;
    color: #f9f9f9;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 20px;
}

.address-copty-btn button:hover{
    background-color: #10364c;
}

.no-addresses{
    text-align: center;
    font-size: 18px;
    color: #777;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blurb-address-info{
    background-color: #ebeff3;
}

.blurb-address-info p{
    color: rgb(117, 117, 117);
    padding: 20px;
}

.address-navbar{
    display: flex;
    align-items: center;
    gap: 20px;
}


@media (max-width: 768px) {
    
    .indie-address-card{
        width: 100%;

    }
    
}