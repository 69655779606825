@font-face {
    font-family: 'Rubik-light';
    src: url('Rubik-Light.ttf') format('ttf');
  }
  
  @font-face {
    font-family: 'Rubik-regular';
    src: url('Rubik-Regular.ttf') format('ttf');
  }
  

  @font-face {
    font-family: 'Rubik-medium';
    src: url('Rubik-Medium.ttf') format('ttf');

  }


  @font-face {
    font-family: 'Rubik-bold';
    src: url('Rubik-Bold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
  }


    @font-face {
        font-family: 'Rubik-black';
        src: url('Rubik-Black.ttf') format('ttf');
    }

    @font-face {
        font-family: 'Rubik-ExtraBold';
        src: url('Rubik-ExtraBold.ttf') format('ttf');
        font-weight: bold;
        font-style: italic;
    }