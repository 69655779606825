/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap'); */


body {
  margin: 0;
  /* background-image: repeating-linear-gradient(135deg, rgba(189,189,189,0.1) 0px, rgba(189,189,189,0.1) 2px,transparent 2px, transparent 4px),linear-gradient(90deg, rgb(255,255,255),rgb(255,255,255)); */
  background-color: #FCFCFC;
  color: #19314a;
  /* font-family: 'Roboto', sans-serif; */
  /* font-family: 'Lato', sans-serif; */

  font-family: 'Poppins', sans-serif;
  font-family: 'Source Sans Pro', sans-serif;
  scroll-behavior: smooth;

  /* font-family: 'Anton', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  display: none; /* Set a small width for scrollbar, you can adjust this value */
}

body::-webkit-scrollbar-thumb {
  background-color: none; /* Remove scrollbar thumb */
}

body::-webkit-scrollbar-track {
  background-color: transparent; /* Remove scrollbar track */
  
}

/* body{
  display: none;
} */

h1,h2,h3,h4,h5,h6{
  font-family: 'Rubik', sans-serif;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
}

p{
  font-family: 'Rubik', sans-serif;
  font-family: 'Source Sans Pro', sans-serif;
  color: #19314a;
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
}

a{
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}


span{
  color: #3d677f;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*standard heading fromat */

.intro-box{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

}
.three-body {
  --uib-size: 35px;
  --uib-speed: 0.8s;
  --uib-color: #3c7d87;
  position: relative;
  display: inline-block;
  height: var(--uib-size);
  width: var(--uib-size);
  animation: spin78236 calc(var(--uib-speed) * 2.5) infinite linear;
 }
 
 .three-body__dot {
  position: absolute;
  height: 100%;
  width: 30%;
 }
 
 .three-body__dot:after {
  content: '';
  position: absolute;
  height: 0%;
  width: 100%;
  padding-bottom: 100%;
  background-color: var(--uib-color);
  border-radius: 50%;
 }
 
 .three-body__dot:nth-child(1) {
  bottom: 5%;
  left: 0;
  transform: rotate(60deg);
  transform-origin: 50% 85%;
 }
 
 .three-body__dot:nth-child(1)::after {
  bottom: 0;
  left: 0;
  animation: wobble1 var(--uib-speed) infinite ease-in-out;
  animation-delay: calc(var(--uib-speed) * -0.3);
 }
 
 .three-body__dot:nth-child(2) {
  bottom: 5%;
  right: 0;
  transform: rotate(-60deg);
  transform-origin: 50% 85%;
 }
 
 .three-body__dot:nth-child(2)::after {
  bottom: 0;
  left: 0;
  animation: wobble1 var(--uib-speed) infinite
     calc(var(--uib-speed) * -0.15) ease-in-out;
 }
 
 .three-body__dot:nth-child(3) {
  bottom: -5%;
  left: 0;
  transform: translateX(116.666%);
 }
 
 .three-body__dot:nth-child(3)::after {
  top: 0;
  left: 0;
  animation: wobble2 var(--uib-speed) infinite ease-in-out;
 }
 
 @keyframes spin78236 {
  0% {
   transform: rotate(0deg);
  }
 
  100% {
   transform: rotate(360deg);
  }
 }
 
 @keyframes wobble1 {
  0%,
   100% {
   transform: translateY(0%) scale(1);
   opacity: 1;
  }
 
  50% {
   transform: translateY(-66%) scale(0.65);
   opacity: 0.8;
  }
 }
 
 @keyframes wobble2 {
  0%,
   100% {
   transform: translateY(0%) scale(1);
   opacity: 1;
  }
 
  50% {
   transform: translateY(66%) scale(0.65);
   opacity: 0.8;
  }
 }
 