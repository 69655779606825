.footer {
    height: 50vh;
    background-color: #ffffff;
    border-top: 30px solid #8d9bac31;
    

  }
  
  .top-section {
    display: flex;
    height: 100%;
    border-bottom: 2px solid #8d9bac31;

  }
  
  .left-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: 100%;
    /* color: #ffffff; */
    background-color: #ffffff;
    gap: 5px;;


  }
  
  .logo {
    width: 100px;
  }
  
  .social-icons {
    display: flex;
    gap: 10px;
  }

  .social-icons a{
    color: #2f2f2f;
    font-weight: 300;
  }
  
  .middle-column {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 10%;
    color: #d6d6d6;
    padding: 33px;

  }

  .middle-kids{
    display: flex;
    flex-direction: column;
    gap: 5px;
    
  }

  .middle-kids a{
    font-size: 15px;
  }


  .right-column {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    color: #d6d6d6;
    gap: 3rem;

  }

  .right-column p{
    color: #2f2f2f;
  }

  .right-column p:hover{
    color: #2b2b2b;
  }

  .middle-column a,
  .right-column a {
    text-decoration: none;
    color: #2f2f2f;
    width: auto;
  
  }


  .middle-column a:hover,
  .right-column a:hover {
    color: #7a7a7a;
  }
  
  nav ul {
    list-style-type: none;
    text-align: left;
  }
  
  nav ul li {
    padding: 5px 0;
  }
  
  nav ul li a {
    text-decoration: none;
    color: #2f2f2f; /* Adjust color as needed */
  }

  nav ul li a:hover {
    color: #151217;
  }
  
  .bottom-section {
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2f2f2f;
    background-color: #ffffff;
 
  }

  .nav-email,
  .nav-phone {
    display: flex;
    flex-direction: column;
  }
  

  .nav-info-ghuk{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

  }

  .icon {
    font-size: 24px;
    margin: 0 10px;
    transition: transform 0.3s ease;
  }

  
  .icon:hover {
    transform: scale(1.1);
  }
  
  .copywrite {
    font-size: 12px;
    word-spacing: 3px;
    color: #6d6d6d;
  }

  @media screen and (max-width: 900px) {
    .footer {
      height: 80vh;
      
    }
  
    .top-section {
      flex-direction: column;
      
    }

    .left-column {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #455b74;
      background-color: #fcfcfc;   
  
    }

    .middle-column {
      display: flex;
      flex-direction: row;
      width: 95%;
      justify-content: center;
      align-items: center;
      padding: 0;
      height: 30vh;
      border: none;
      

    }
  
    .middle-kids{
      display: flex;
      flex-direction: row;
      gap: 20px;
      font-size: 20px;
 
    }

    .right-column {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding-bottom: 2rem;
    }

    .nav-email{
      text-align: center;
    }
  }


  @media(max-height: 500px) {

    .footer {
      height: 155vh;
    }
  
    .top-section {
      flex-direction: column;
      

    }
  }