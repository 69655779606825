.chat-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 70vh;
  width: 26%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.36);
  position: fixed;
  bottom: 120px;
  right: 70px;
  z-index: 1000;
  border-radius: 5px;
}

.ins-box {
  display: flex;
  flex-direction: column;
}

.chatWithMeButton {
  cursor: pointer;
  box-shadow: 0px 0px 16px 6px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  background-image: url(../../assets/images/favicon1.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 45px;
  height: 45px;
  position: fixed;
  bottom: 100px;
  right: 14px;
  z-index: 1000;
  filter: grayscale(0.5);
}

.chatWithMeButton:hover {
  filter: grayscale(0);
  scale: 1.1;
}

.chat-header {
  padding: 10px;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  background-color: rgb(9, 34, 61);
}

.chat-box {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.chat-box::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.chat-bubble {
  max-width: 65%;
  padding: 5px;
  margin-bottom: 12px;
  border-radius: 10px;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
}

.chat-bubble p {
  margin: 0;
  color: black;
  font-size: 12px;
  text-align: left;
}

.chat-bubble.self {
  align-self: flex-end;
  background-color: #e0f7fa;
}

.timestamp {
  font-size: 8px;
  color: #888;
  align-self: flex-end;
}

.chat-input {
  display: flex;
  padding: 5px;
  background-color: #f3f3f3;
  border-top: 1px solid #ccc;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 5px;
}

.chat-input button {
  padding: 0;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: none;
}


@media (max-width: 768px) {
  .chat-container {
    width: 100%;
    height: 75vh;
    bottom: 10rem;
    right: 0;
    border-radius: 0;
  }
}
