.for-better-experience-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }


  .for-better-ex-section{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10%;
 
  }
  
  .for-better-experience-form {
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    border-radius: 8px;
    display: flex;
    border-left: 2px solid #455b74;
  }
  
  .for-better-experience-form h1 {
    margin-bottom: 5px;
   
  }

  .for-better-experience-image-container{
    width: 30%;
    height: 100%;

  }


  .for-better-experience-image-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .for-better-experience-form label {
    display: block;
    margin-bottom: 5px;
  }

  .for-better-ex-section h3{
    color: #22416a;
    font-weight: 300;
  }
  
  .for-better-experience-form input[type="radio"] {
    margin-right: 5px;
  }
  
  .for-better-experience-form button {
    margin-top: 20px;
    padding: 10px;
    width: 40%;
    border: none;
    border-radius: 5px;
    background-color: #1b2634;
    color: #fff;
    cursor: pointer;
  }
  
  .for-better-experience-form button:hover {
    background-color: #0056b3;
  }
  


  
@media screen and (max-width: 900px) {
    .for-better-experience-form {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }


    .for-better-experience-image-container{
        width: 100%;
        height: 15%;
    
      }


      .for-better-ex-section{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 10%;
     
      }
    }