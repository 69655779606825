.address-modal-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 2;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.5s ease, transform 0.5s ease;
    animation: fadeIn 0.5s ease forwards;
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }


.address-modal-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.9);
    width: 80%;
    height: 30%;
    color: black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.address-modal-container .pran{
    color: black;
    font-size: 18px;
    
}

.address-modal{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #162230;
    width: 90%;
    height: 40%;
    gap: 10px;
    color: white;
    font-size: 14px;

}



.address-success-modal{
    font-size: xx-small;
}


.address-modal svg:hover{
    cursor: pointer;
    transform: scale(1.1);
    transition: 0.3s ease-in-out all;
    color: orange;

}



@media screen and (max-width: 900px) {

    .address-modal-container{
        width: 90%;
        
    }
    .address-modal{
        font-size: 13px;
        width: 90%;
        padding: 10px;
    }
}


@media only screen and (max-height: 376px) {


    .address-modal{
        padding: 10px;
    }


    .address-modal p{
        font-size: 10px;

    }
  
  }
  