.logo-container{
    position: fixed;
    top: 10px;
    left: 10px;
    width: 8rem;
    height: 3.5rem;
    z-index: 2000;    
}

.logo-container img{
    width: 100%;
    height: 100%;

}


@media screen and (max-width: 900px) {

    .logo-container{
        top: 15px;
        position: fixed;
        top: 10px;
        left: 10px;
        width: 7rem;
        height: 3rem;
        z-index: 1000;
        
    }
    

}


