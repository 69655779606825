.drop-date-settings {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .no-drop-dates {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    color: #666;
    font-size: 18px;
  }
  
  .schedule-list {
    list-style: none;
    padding: 0;
  }
  
  .schedule-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
  }
  
  .schedule-item:hover {
    transform: scale(1.02);
  }
  
  .schedule-info {
    flex: 1;
    padding-right: 15px;
  }
  
  .schedule-info span {
    font-size: 16px;
    color: #444;
    font-weight: 600;
  }
  
  .schedule-info p {
    font-size: 14px;
    color: #666;
    margin: 2px 0;
  }
  
  .schedule-actions {
    display: flex;
    gap: 10px;
  }
  
  .edit-btn, .delete-btn {
    padding: 8px 12px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .edit-btn {
    background-color: #4caf50;
    color: #fff;
  }
  
  .edit-btn:hover {
    background-color: #45a049;
  }
  
  .delete-btn {
    background-color: #f44336;
    color: #fff;
  }
  
  .delete-btn:hover {
    background-color: #e53935;
  }
  

  .edit-form {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    text-align: left;
  }
  
  .edit-form h3 {
    margin-bottom: 15px;
    color: #333;
  }
  
  .edit-form label {
    display: block;
    margin: 10px 0;
  }
  
  .edit-form input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .save-btn {
    background-color: #4CAF50;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .cancel-btn {
    background-color: #f44336;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
